<template>
  <vs-popup title="Approval Progress" :active="isActive" v-on:update:active="emitModalIsActive">
    <div class="w-full">
      <ApprovalProgress ref="approvalProgress" v-if="isActive" :type="type" :id-ref="idRef"/>
    </div>
  </vs-popup>
</template>

<script>
import ApprovalProgress from '@/views/components/approval-progress/ApprovalProgress'

export default {
  name: 'ModalApprovalProgress',
  props: {
    isActive: { required: true },
    type: { required: true },
    idRef: { required: true }
  },
  components: {
    ApprovalProgress
  },
  methods: {
    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
